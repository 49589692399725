import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid/models";
import ScaleIcon from '@mui/icons-material/Scale';
import Avatar from '@mui/material/Avatar';
import Decimal from 'decimal.js';
import { Tooltip } from "@mui/material";
import { GridEditInputCell } from "@mui/x-data-grid";

export const allowToDuplicateTowaryInDostawa: boolean = true;

export const magazynColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'towarName',
    headerName: 'Nazwa towaru',
    editable: false,
    sortable: false,
    hideable: false,
    width: 380,
    disableColumnMenu: true,
    renderCell: ({ value, row }) => (
      <>{value}
        {row.doneRozwaga && <Tooltip title="Rozwaga"><Avatar className="miniAvatar" sx={{ marginLeft: '5px'}} variant="rounded"><ScaleIcon style={{fontSize: '16px', color: 'white'}}/></Avatar></Tooltip>}
        {row.doneRw && <Tooltip title="Rozchód wewnętrzny"><Avatar className="miniAvatar" sx={{ marginLeft: '5px'}} variant="rounded">RW</Avatar></Tooltip>}
        {row.donePw && <Tooltip title="Przychod wewnętrzny"><Avatar className="miniAvatar" sx={{ marginLeft: '5px'}} variant="rounded">PW</Avatar></Tooltip>}
        {row.donePwWithoutPriceValidation && <Tooltip title="Zmiana wewnętrzna"><Avatar className="miniAvatar" sx={{ marginLeft: '5px'}} variant="rounded">ZW</Avatar></Tooltip>}
      </>
    )
  },
  {
    field: 'additionalInfo',
    headerName: 'Dodatkowa informacja',
    editable: false,
    sortable: false,
    hideable: false,
    width: 150,
    disableColumnMenu: true,
    headerClassName: "alignLeft",
    renderCell: ({ value, row }) => {
      if (row.fromPw) {
        return <Tooltip title="Przychod wewnętrzny"><Avatar className="miniAvatar" variant="rounded">PW</Avatar></Tooltip>;
      }
      if (row.fromPwWithoutPriceValidation) {
        return <Tooltip title="Zmiana wewnętrzna"><Avatar className="miniAvatar" variant="rounded">ZW</Avatar></Tooltip>;
      }
      return value;
    }
  },
  {
    field: 'dostawca',
    headerName: 'Dostawca',
    editable: false,
    sortable: false,
    hideable: false,
    width: 200,
    disableColumnMenu: true
  },
  {
    field: 'quantity',
    headerName: 'Ilość',
    type: 'number',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    headerClassName: "alignCenter",
    align: "center"
  },
  {
    field: 'price',
    headerName: 'Cena (netto)',
    type: "number",
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    headerClassName: "alignCenter",
    align: "center",
    valueFormatter: ( value ) => (value as string).toString()
  },
  {
    field: 'sumNetto',
    headerName: 'Wartość (netto)',
    type: "number",
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    headerClassName: "alignCenter",
    align: "center",
    valueFormatter: ( value ) => (value as string).toString()
  },
  {
    field: 'numberPZ',
    headerName: 'PZ',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 125
  },
  {
    field: 'barcode',
    headerName: 'Kod kreskowy',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 120
  },
  {
    field: 'createdDate',
    headerName: 'Utworzony',
    type: 'dateTime',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 170,
    valueGetter: ( value ) => value && new Date(value)
  }
];


export interface IPrzychodWewnetrzny {
  id: string;
  towarName: string;
  towarId: number;
  quantity: number;
  price: number;
  sum: number;
}
export const przychodWewnetrznyColumns: GridColDef[] = [
  {
    field: 'towarName',
    headerName: 'Nazwa towaru',
    editable: false,
    sortable: false,
    hideable: false,
    flex: 1,
    disableColumnMenu: true
  },
  {
    field: 'quantity',
    headerName: 'Ilość',
    type: 'number',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    align: "center",
    renderEditCell: (params: GridRenderEditCellParams) => (
      <GridEditInputCell {...params} onKeyDown={ (e: any) => (
        e.keyCode === 190 || // .
        e.keyCode === 188 || // ,
        e.keyCode === 69 || // e & E
        e.keyCode === 189 ) // -
        && e.preventDefault() }/>
    ),
    valueParser(value, params?) {
      return value === "0" || value === "" ? null : value;
    }
  },
  {
    field: 'price',
    headerName: 'Cena',
    type: "number",
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    align: "center",
    renderEditCell: (params: GridRenderEditCellParams) => (
      <GridEditInputCell {...params} onKeyDown={ (e: any) => (
        e.keyCode === 69 || // e & E
        e.keyCode === 189 ) // -
        && e.preventDefault() }/>
    ),
    // valueParser(value, params?) {
    //   var currentValue = value as string;
    //   if (currentValue === "0") {
    //     currentValue = currentValue.replace('0', '0.01');
    //   }
    //   var data = Number(currentValue);
    //   return data >= 0.01 ? data : null
    // },
    valueFormatter: ( value ) => (value !== null && value !== undefined ? (value as string).toString() : null)
  },
  {
    field: 'sum',
    headerName: 'Suma',
    type: 'number',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    align: "center",
    renderCell: ({ value, row }) => {
      if (row.quantity !== null && row.quantity !== undefined && row.price !== null && row.price !== undefined) {
        let x = new Decimal(row.quantity);
        let y = new Decimal(row.price);
        return x.times(y).toFixed(2).toString();
      } else {
        return null;
      }
    }
  },
];