import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import EditableGrid from '../Features/EditableGrid';
import { Autocomplete, darken, Grid, IconButton, InputAdornment, lighten, styled, TextField, Tooltip } from '@mui/material';
import { getDostawcyApi } from '../Services/SupplyService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros } from '../Helpers/AxiosCustomHeaders';
import { getAllMagazynApi, getAllTowaryInMagazynApi } from '../Services/MagazynService';
import { magazynColumns } from '../Models/Magazyn';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ClearIcon  from '@mui/icons-material/Clear';
import { pl } from "date-fns/locale/pl";
import ModalElement from '../Features/Modal';
import { OpenMagazynModal } from '../SiteComponents/MagazynPage/OpenMagazynModal';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { allowOnlyNumbers } from '../Helpers/TextFieldTypeNumber';
import { BarcodeScannerModal } from '../Features/BarcodeScannerModal';

function MagazynPage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const [currentDostawcaId, setCurrentDostawcaId] = useState<number | null>(null);
  const [currentTowarId, setCurrentTowarId] = useState<number | null>(null);
  const [dateFromValueForSearch, setDateFromValueForSearch] = useState<Date | null>(null);
  const [dateToValueForSearch, setDateToValueForSearch] = useState<Date | null>(null);
  const [priceValueForSearch, setPriceValueForSearch] = useState<string>('');
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [quantitySum, setQuantitySum] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  const [openMagazynModal, setOpenMagazynModal] = useState(false);
  const [doubleClickedMagazynRow, setDoubleClickedMagazynRow] = useState<any>(null);
  const [openBarcodeScannerModal, setOpenBarcodeScannerModal] = useState(false);
  const [barcode, setBarcode] = useState<string>("");
  const [barcodeTextFieldValue, setBarcodeTextFieldValue] = useState<string>('');
  
  const [towaryInMagazynOptions, setTowaryInMagazynOptions] = useState<[]>([]);
  const getAllTowaryInMagazyn = useCallback(async () => {
    await getAllTowaryInMagazynApi().then(
      async (response: AxiosResponse) => {
        setTowaryInMagazynOptions(response.data);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
      });
  }, []);

  const [dostawcyOptions, setDostawcyOptions] = useState<[]>([]);
  const getDostawcy = useCallback(async () => {
    if (appContext?.userData?.userRole !== UserRole.dostawca) {
      await getDostawcyApi().then(
        (response: AxiosResponse) => {
          setDostawcyOptions(response.data)
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        }
      );
    }
  }, [appContext?.userData?.userRole]);

  const initialize = useCallback(async (paginationModel: {pageSize: number; page: number;}, dostawcaId: number | null, towarId: number | null,
    dateFromValueForSearch: Date | null, dateToValueForSearch: Date | null, priceValueForSearch: string, barcodeForSearch: string) => {
    setLoadingInitialization(true);
    
    if (appContext?.userData?.userRole === UserRole.dostawca && dostawcaId === null)
    {
      dostawcaId = Number(appContext?.userData?.userId);
      setCurrentDostawcaId(dostawcaId);
    }

    await getAllMagazynApi(paginationModel, dostawcaId, towarId, dateFromValueForSearch, dateToValueForSearch, priceValueForSearch, barcodeForSearch).then(
      (response: AxiosResponse) => {
        setRows(response.data.results);
        setTotal(response.data.total);
        setQuantitySum(response.data.quantitySum);
        setLoadingInitialization(false);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    )
  }, [appContext?.userData?.userId, appContext?.userData?.userRole]);
  useEffect(() => {
    initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId, currentTowarId, dateFromValueForSearch, dateToValueForSearch, priceValueForSearch, barcode);
    getAllTowaryInMagazyn();
    getDostawcy();
  }, [initialize, getAllTowaryInMagazyn, getDostawcy, appContext?.rowsNumberPageModel, page, currentDostawcaId, currentTowarId, dateFromValueForSearch, dateToValueForSearch, priceValueForSearch, barcode]);

  const doubleClickMagazynRowAction = async (row: any) => {
    if (appContext?.userData?.userRole !== UserRole.dostawca) {
      setDoubleClickedMagazynRow(row);
      setOpenMagazynModal(true);
    }
  };
  
  const closeMagazynModalAction = () => {
    setOpenMagazynModal(false);
		initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId, currentTowarId, dateFromValueForSearch, dateToValueForSearch, priceValueForSearch, barcode);
  };

  const listener = ((e: KeyboardEvent) => {
    var el = document.getElementById('textfield') as HTMLInputElement;
    if (document.activeElement !== el) {
      if (e.key === 'Enter') {
        setBarcode(el.value);
      } else if (e.key === '0' || e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' || e.key === '5' || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9') {
        if (el.value.length === 10) {
          el.value = e.key;
        } else {
          el.value = el.value + e.key;
        }
        var eventt = new Event('input', { 'bubbles': true, 'cancelable': true });
        el.dispatchEvent(eventt);
      }
    }
  }) as EventListener;

  useEffect(() => {
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);

    // const listener = ((event: ClipboardEvent) => {
    //   setBarcodeTextFieldValue(event.clipboardData?.getData("text") ?? "");
    // }) as EventListener;
    // window.addEventListener("paste", listener);

    // return () => window.removeEventListener("paste", listener);
  }, [listener]);

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    zIndex: 1,
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  const GroupItems = styled('ul')({
    padding: 0,
  });
  return (
    <><h1>{(appContext?.userData?.userRole === UserRole.dostawca) ? "Magazyn" : "Magazyn"}</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "20px !important", textAlign: 'center'}}>
      {(appContext?.userData?.userRole !== UserRole.dostawca) ? <Grid item xs={12} sm={8} md={4.8} lg={4.2} xl={3}>
        <Autocomplete
          options={dostawcyOptions}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          onChange={(e, data: any) => { if (data != null)
            {setCurrentDostawcaId(data.id);}
            else {setCurrentDostawcaId(null);};}}
          defaultValue={null}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Wybierz dostawce"
            />
          )}
        />
      </Grid> : <></>}
      <Grid item xs={12} sm={8}
        md={appContext?.userData?.userRole !== UserRole.dostawca ? 4.8 : 7}
        lg={appContext?.userData?.userRole !== UserRole.dostawca ? 4.2 : 6}
        xl={appContext?.userData?.userRole !== UserRole.dostawca ? 3 : 4}>
        <Autocomplete  // rerender modal each time because in autocomplete there is no value defined so choosen elements stay as picked but we are removing them to not choose second time
          defaultValue={null}
          options={towaryInMagazynOptions}
          onChange={(e, data: any) => 
            { if (data != null) {setCurrentTowarId(data.key);}
              else {setCurrentTowarId(null);};}}
          groupBy={(option) => option.nazwaGrupy}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          classes={{ paper: "optionsForTowaryForDostawca" }}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader onClick={() => {
                (document.getElementById("groupItems" + params.key)!).style.display === "none" ?
                (document.getElementById("groupItems" + params.key)!).style.display = "block" :
                (document.getElementById("groupItems" + params.key)!).style.display = "none";
                }}>Grupa {params.group} ({(params.children as any).length})</GroupHeader>
              <GroupItems id={"groupItems" + params.key} style={{display: "none"}}>{params.children}</GroupItems>
            </li>
          )}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Wybierz towar"
            />
          )}
        />
      </Grid>
    </Grid>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "20px !important", textAlign: 'center'}}>
      <Grid item xs={12} sm={8} md={2.7} lg={2.8} xl={1.8}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
          <MobileDatePicker sx={{width: "100%"}}
            label="Utworzony OD"
            value={dateFromValueForSearch}
            onChange={(newValue) => setDateFromValueForSearch(newValue)}
            closeOnSelect
            slotProps={{
              toolbar: {
                hidden: true,
              },
              actionBar: {
                actions: [],
              },
              textField: {
                InputProps: {
                  placeholder: undefined,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={(event) => {
                        event.preventDefault();
                        setDateFromValueForSearch(null);
                        }}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={8} md={2.7} lg={2.8} xl={1.8}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
            <MobileDatePicker sx={{width: "100%"}}
              label="Utworzony DO"
              value={dateToValueForSearch}
              onChange={(newValue) => setDateToValueForSearch(newValue)}
              closeOnSelect
              slotProps={{
                toolbar: {
                  hidden: true,
                },
                actionBar: {
                  actions: [],
                },
                textField: {
                  InputProps: {
                    placeholder: undefined,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={(event) => {
                          event.preventDefault();
                          setDateToValueForSearch(null);
                          }}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }
              }}
            />
        </LocalizationProvider>
      </Grid>
      {(appContext?.userData?.userRole !== UserRole.dostawca) ? <><Grid item xs={12} sm={8} md={1.7} lg={1} xl={1}>
        <TextField
          fullWidth
          label={"Cena"}
          autoComplete="cc-number"
          onInput = {(event) => {allowOnlyNumbers(event, true)}}
          onKeyUp={(event) => event.key === 'Enter' && setPriceValueForSearch((event.target as HTMLInputElement).value)}
          onBlur={(event) => setPriceValueForSearch(event.target.value)}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment className='switchInAutocomplete' position="start">
          //       <IconButton onClick={(event) => {
          //         event.preventDefault();
          //         setPriceValueForSearch('');
          //         }}>
          //         <ClearIcon />
          //       </IconButton>
          //     </InputAdornment>
          //   )
          // }}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={2.5} lg={1.8} xl={1.4}>
        <TextField
          id='textfield'
          fullWidth
          label={"Kod kreskowy"}
          autoComplete="cc-number"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            //setBarcodeTextFieldValue(event.target.value);
          }}
          onKeyUp={(event) => event.key === 'Enter' && setBarcode((event.target as HTMLInputElement).value)}
          onBlur={(event) => setBarcode(event.target.value)}
          value={barcodeTextFieldValue}
          onInput = {(event) => {
            if ((event.target as HTMLInputElement).value !== null && (event.target as HTMLInputElement).value !== undefined && (event.target as HTMLInputElement).value !== '') {
              (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.replace(/[^0-9.]/g,'').toString().slice(0, 10);
              setBarcodeTextFieldValue((event.target as HTMLInputElement).value);
            }
          }}
          InputProps={{
            endAdornment: 
              <InputAdornment position="end" style={{marginRight: '-10px', marginLeft: '0px'}}>
                {(barcodeTextFieldValue === '') ?
                <Tooltip title="Skanuj kod kreskowy" placement="left">
                  <IconButton onClick={() => setOpenBarcodeScannerModal(true)}>
                    <CropFreeIcon style={{color: 'black'}} />
                  </IconButton>
                </Tooltip>
                :
                <IconButton onClick={(event) => {event.preventDefault(); setBarcodeTextFieldValue(''); setBarcode('');}}>
                  <ClearIcon />
                </IconButton>}
              </InputAdornment>
          }}
        />
      </Grid>
      </> : <></>}
    </Grid>
    <EditableGrid
      columns={appContext?.userData?.userRole === UserRole.dostawca ?
        quantitySum === null ?
          magazynColumns.filter(x => x.field !== "price" && x.field !== "sumNetto").map(c => {
            if (c.field === 'quantity') {
              c.headerName = `Ilość`;
              return c;
            } else {
              return c;
            }
          }) :
          magazynColumns.filter(x => x.field !== "price" && x.field !== "sumNetto").map(c => {
            if (c.field === 'quantity') {
              c.headerName = `Ilość\n(Suma ${quantitySum})`;
              return c;
            } else {
              return c;
            }
          })
        :
        quantitySum === null ?
          magazynColumns.map(c => {
            if (c.field === 'quantity') {
              c.headerName = `Ilość`;
              return c;
            } else {
              return c;
            }
          }) :
          magazynColumns.map(c => {
            if (c.field === 'quantity') {
              c.headerName = `Ilość\n(Suma ${quantitySum})`;
              return c;
            } else {
              return c;
            }
          })
      }
      rows={rows}
      editAction={async () => false}
      loading={loadingInitialization}
      setPaginationModel={paginationModelChange}
      paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
      total={total}
      setQueryOptions={() => null}
      doubleClickRowAction={(row: any) => {doubleClickMagazynRowAction(row)}}
      deleteAction={() => null}
      hideDeleteAction={true}
      hideEditAction={true}
      hideActionColumn={true}
      checkboxSelection={false}
      disableRowSelectionOnClick={false}
      setRowsSelectionModel={() => null}
      rowSelectionModel={undefined}
      hideFooter={false}
      hideFooterSelectedRowCount={true}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
     />
    <ModalElement handleClose={() => { setOpenMagazynModal(false) }} open={openMagazynModal} centerCloseButton={false} modalContent={
        <OpenMagazynModal doubleClickedMagazynRow={doubleClickedMagazynRow} closeMagazynModalAction={closeMagazynModalAction} />
      } additionalStyles={{top: '7% !important'}}/>
    <ModalElement handleClose={() => { setOpenBarcodeScannerModal(false) }} open={openBarcodeScannerModal} centerCloseButton={true} modalContent={
        <BarcodeScannerModal setBarcode={(barcode: string) => {setBarcode(barcode); setBarcodeTextFieldValue(barcode);}} closeBarcodeScannerModalAction={() => setOpenBarcodeScannerModal(false)} />
      } additionalStyles={{top: '7% !important'}}/>
    </>
  );
};

export default MagazynPage;