import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import AddIcon from '@mui/icons-material/Add';
import EditableGrid from '../Features/EditableGrid';
import { Autocomplete, Grid, TextField } from '@mui/material';
import ModalElement from '../Features/Modal';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import LoadingButton from '@mui/lab/LoadingButton';
import { enqueueSnackbar } from 'notistack';
import { searchDostawcaByNameApi } from '../Services/UserService';
import { IWydanie, wydanieColumns } from '../Models/Wydanie';
import AddWydanieModal from '../SiteComponents/WydaniePage/AddWydanieModal';
import { createWydanieApi, editWydanieApi, getAllWydanieApi, removeWydanieApi } from '../Services/WydanieService';
import { OpenWydanieDetailsModal}  from '../SiteComponents/WydaniePage/OpenWydanieDetailsModal';
import { getAllTowaryApi } from '../Services/SupplyService';
import { Debounce } from '../Helpers/ButtonDebouncing';
import { ZwrotColumn } from '../SiteComponents/AdditionalColumns';

function WydaniePage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<IWydanie[]>([]);
  const [choosenOdbiorcaId, setChoosenOdbiorcaId] = useState<number | null>(null);
  const [doubleClickedWydanieRow, setDoubleClickedWydanieRow] = useState<IWydanie | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  const [loadingCreateButton, setLoadingCreateButton] = useState<boolean>(false);
	const [value, setValue] = useState<any>(null);
  const [optionsTowary, setOptionsTowary] = useState<any[]>([]);

  const [dostawaDate, setDostawaDate] = useState<Date | null>(null);
  const [dostawcaNumber, setDostawcaNumber] = useState<string>("");
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  
  const initialize = useCallback(async () => {
    if (choosenOdbiorcaId) {
      setLoadingInitialization(true);
      await getAllWydanieApi(choosenOdbiorcaId, Number(appContext?.rowsNumberPageModel), page).then(
        (response: AxiosResponse) => {
          setRows(response.data.results);
          setTotal(response.data.total);
          setLoadingInitialization(false);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingInitialization(false);
        });
    } else {
      setLoadingInitialization(true);
      await getAllWydanieApi(null, Number(appContext?.rowsNumberPageModel), page).then(
        (response: AxiosResponse) => {
          setRows(response.data.results);
          setTotal(response.data.total);
          setLoadingInitialization(false);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingInitialization(false);
        });
    }
  }, [choosenOdbiorcaId, appContext?.rowsNumberPageModel, page]);
  useEffect(() => { initialize(); }, [initialize]);

	const getAllTowary = useCallback(async () => {
    await getAllTowaryApi(true).then(
      async (response: AxiosResponse) => {
        setOptionsTowary(response.data);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
      });
  }, []);
  useEffect(() => { getAllTowary(); }, [getAllTowary]);

  const [optionsForOdbiorcy, setOptionsForOdbiorcy] = useState([]);
  const onIdOdbiorcaRaksInputChange = (event: any, value: string, reason: any) => {
    if (value) {
      searchDostawcaByNameApi(value).then(
        (response: AxiosResponse) => {
          setOptionsForOdbiorcy(response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        });
    } else {
      setOptionsForOdbiorcy([]);
    }
  };
  
  const deleteAction = async (row: IWydanie) => {
    setLoadingInitialization(true);
    await removeWydanieApi(row.id).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };

  const addAction = async () => {
    if (dostawaDate === null || dostawaDate === undefined ||
    dostawcaNumber === null || dostawcaNumber === "" || dostawcaNumber === undefined ||
    orderNumber === null || orderNumber === "" || orderNumber === undefined) {
      enqueueSnackbar('Proszę uzupełnić wszystkie pola.', { variant: 'info' });
    } else {
      setLoadingCreateButton(true);
      await createWydanieApi(dostawaDate, choosenOdbiorcaId!, dostawcaNumber, orderNumber//, driverName, driverNumber, Number(euroPalletCount), Number(normalPalletCount)
        ).then(
        async (response: AxiosResponse) => {
          await Debounce(setLoadingCreateButton, false);
          initialize();
          setOpenAddModal(false);
          handleSuccess(response);
          
          setDostawaDate(null);
          setDostawcaNumber("");
          setOrderNumber("");
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingCreateButton(false);
        }
      )
    }
  };

  const editAction = async (newRow: IWydanie, oldRow: IWydanie) : Promise<boolean> => {
    if (newRow.dostawaDate === null || newRow.dostawaDate === undefined ||
      newRow.dostawcaNumber === null || newRow.dostawcaNumber === "" || newRow.dostawcaNumber === undefined ||
      newRow.orderNumber === null || newRow.orderNumber === "" || newRow.orderNumber === undefined
    ) {
      enqueueSnackbar('Proszę uzupełnić wszystkie pola.', { variant: 'info' });
      return false;
    } else {
      return await editWydanieApi(
        newRow.id,
        newRow.dostawaDate,
        newRow.odbiorcaId,
        newRow.dostawcaNumber,
        newRow.orderNumber,
        newRow.driverName,
        newRow.driverNumber,
        newRow.euroPalletCount,
        newRow.normalPalletCount)
        .then(
          (response: AxiosResponse) => {
            initialize();
            handleSuccess(response);
            return true;
          })
        .catch(
          (error: AxiosError) => {
            handleErros(error, appContext);
            return false;
          }
        );
    }
  };
  
  const doubleClickRowAction = async (row: IWydanie) => {
    setDoubleClickedWydanieRow(row);
    setOpenDetailsModal(true);
  };

  const closeDetailsModalAction = () => {
    setOpenDetailsModal(false);
    initialize();
  };

  return (
    (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) ?
    <><h1>Wydanie zewnętrzne</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "28px !important", textAlign: 'center'}}>
      <Grid item xs={12} sm={8} md={6} lg={5}>
        <Autocomplete
					value={value}
          options={optionsForOdbiorcy}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          classes={{ paper: "optionsForIdDostawcaRaks" }}
          onChange={(e, data: any) => {
            if (data != null) { setValue(data); setChoosenOdbiorcaId(data.id) } else { setValue(null); setChoosenOdbiorcaId(null); }
          }}
          onInputChange={ (event: any, value: string, reason: any) => {onIdOdbiorcaRaksInputChange(event, value, reason)}}
          defaultValue={null}
          noOptionsText={'Zacznij pisac żeby zobaczyć opcje'}
          renderInput={(params) =>
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={'Odbiorca z Raksa'}
              autoComplete="cc-number"
              />
            }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1}>
        <LoadingButton className='customAddLoadingButton'
          endIcon={<AddIcon sx={{m: '0px', fontSize: "30px !important"}}/>}
          loadingPosition="center"
          disabled={choosenOdbiorcaId === null}
          onClick={() => setOpenAddModal(true)}>
        </LoadingButton>
      </Grid>
    </Grid>
    <EditableGrid
      columns={wydanieColumns.concat(ZwrotColumn(null, null, false))}
      rows={rows}
      editAction={editAction}
      loading={loadingInitialization}
      setPaginationModel={paginationModelChange}
      paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
      total={total}
      setQueryOptions={() => null}
      doubleClickRowAction={(row: IWydanie) => doubleClickRowAction(row)}
      deleteAction={deleteAction}
      hideDeleteAction={false}
      hideEditAction={false}
      hideActionColumn={false}
      checkboxSelection={false}
      disableRowSelectionOnClick={true}
      setRowsSelectionModel={() => null}
      rowSelectionModel={undefined}
      hideFooter={false}
      hideFooterSelectedRowCount={false}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
    />
    <ModalElement handleClose={() => setOpenAddModal(false)} open={openAddModal} centerCloseButton={false} additionalStyles={{width: '40% !important', minWidth: '500px !important', top: '10% !important'}}
      modalContent={
        <AddWydanieModal
          setDostawaDate={setDostawaDate}
          setDostawcaNumber={setDostawcaNumber}
          setOrderNumber={setOrderNumber}
          addAction={addAction}
          loadingCreateButton={loadingCreateButton}
          />
        }
    />
    <ModalElement handleClose={closeDetailsModalAction} open={openDetailsModal} additionalStyles={{top: '10% !important'}} centerCloseButton={false}
      modalContent={ <OpenWydanieDetailsModal doubleClickedWydanieRow={doubleClickedWydanieRow!} optionsTowary={optionsTowary} closeDetailsModalAction={closeDetailsModalAction} /> }
    />
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default WydaniePage;