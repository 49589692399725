import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import { Grid, Paper } from '@mui/material';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import { getWydanieHistoryApi } from '../Services/HistoryService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros } from '../Helpers/AxiosCustomHeaders';
import { IMagazynDostawaDetailHistory, IWydanieHistory } from '../Models/Wydanie';

export const paramNameWzHistory = "wydanieId";

function ReportWzHistoryPage() {
  const appContext = useContext(AppContext);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const wydanieId = params.get(paramNameWzHistory);
  const [wz, setWz] = useState<IWydanieHistory>();
  const [dostawaDetailDepth, setDostawaDetailDepth] = useState(0);

  let i = 0;
  const calculateWidth = 12/dostawaDetailDepth >= 4 ? 4 : 12/dostawaDetailDepth;

  const printText = (dostawaDetail: IMagazynDostawaDetailHistory) => {
    return <>
    {dostawaDetail?.towarName}
    {dostawaDetail?.additionalInfo &&
      <Typography sx={{textAlign: 'left', pl: 1, pr: 1}}>PZ: {dostawaDetail?.additionalInfo}</Typography>}
    {dostawaDetail?.transformationNote && 
      <Typography sx={{textAlign: 'left', pl: 1, pr: 1}}>T: {dostawaDetail?.transformationNote}</Typography>}
    </>
  }
  
  const initialize = useCallback(async (wydanieId: string | null) => {    
    if (appContext?.userData?.userRole !== UserRole.admin) {
      return;
    }
    if (wydanieId === null) {
      return;
    }

    await getWydanieHistoryApi(wydanieId).then(
      (response: AxiosResponse) => {
        setWz(response.data);
        let depth = 0;
        (response.data as IWydanieHistory).wydanieDetails.forEach(wydanieDetail => {
          wydanieDetail.magazynToWydanieDetails.forEach(magazynToWydanieDetail => {
            if (magazynToWydanieDetail.dostawaDetail != null) {
              depth = depth === 0 ? 1 : depth;
              if (magazynToWydanieDetail.dostawaDetail.dostawaDetail != null) {
                depth = depth === 1 ? 2 : depth;
                if (magazynToWydanieDetail.dostawaDetail.dostawaDetail.dostawaDetail != null) {
                  depth = depth === 2 ? 3 : depth;
                  if (magazynToWydanieDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail != null) {
                    depth = depth === 3 ? 4 : depth;
                    if (magazynToWydanieDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail != null) {
                      depth = depth === 4 ? 5 : depth;
                      if (magazynToWydanieDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail != null) {
                        depth = depth === 5 ? 6 : depth;
                        if (magazynToWydanieDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail.dostawaDetail != null) {
                          depth = depth == 6 ? 7 : depth;
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        });
        setDostawaDetailDepth(depth);
        //console.log(response.data);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
      }
    )
  }, [appContext?.userData?.userRole, wydanieId]);
  useEffect(() => {
    initialize(wydanieId);
  }, [initialize, wydanieId]);

  
  return (
    (appContext?.userData?.userRole === UserRole.admin) ?
    <><h1>Historia WZ</h1>
    {wydanieId === null || wydanieId === undefined ?
    <h4 style={{textAlign: 'center', marginTop: '-10px'}}>Proszę wyszukać zakończone WZ na liście WZ-tek i kliknąć je dwa razy a następnie przycisk "Zobacz historie WZ".</h4> :
    <><Grid container rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}} sx={{textAlign: 'center', p: '3px', marginTop: '-10px'}}>
      <Grid container item rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}}>
        <Grid item xs={12} sm={9} md={7} lg={6} xl={5}>
          <Paper sx={{boxShadow: 3}} className='historyWzPaper'>{wz?.number}<br></br>{wz?.odbiorca}
            <Grid container rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}}>
              <Grid item xs={6}>
              <b>Numer zamówienia</b>
              </Grid>
              <Grid item xs={6}>
              <b>Numer dostawcy</b>
              </Grid>
              <Grid item xs={6}>
              {wz?.orderNumber}
              </Grid>
              <Grid item xs={6}>
              {wz?.dostawcaNumber}
              </Grid>
              <Grid item xs={6}>
              <b>Data utworzenia</b>
              </Grid>
              <Grid item xs={6}>
              <b>Data dostawy</b>
              </Grid>
              <Grid item xs={6}>
              {new Date(wz?.createdDate!).toLocaleString('pl',{dateStyle: 'long', timeStyle: 'medium'})}
              </Grid>
              <Grid item xs={6}>
              {new Date(wz?.dostawaDate!).toLocaleString('pl',{dateStyle:'long'})}
              </Grid>
              <Grid item xs={3}>
              <b>Palet</b>
              </Grid>
              <Grid item xs={3}>
              <b>Euro palet</b>
              </Grid>
              <Grid item xs={3}>
              <b>Kierowca</b>
              </Grid>
              <Grid item xs={3}>
              <b>Numer kierowcy</b>
              </Grid>
              <Grid item xs={3}>
              {wz?.normalPalletCount}
              </Grid>
              <Grid item xs={3}>
              {wz?.euroPalletCount}
              </Grid>
              <Grid item xs={3}>
              {wz?.driverName}
              </Grid>
              <Grid item xs={3}>
              {wz?.driverNumber}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={2} sx={{marginTop: "10px !important", marginBottom: "10px !important"}}>
        Pozycja na Wz
      </Grid>
      <Grid item xs={2} sx={{marginTop: "10px !important", marginBottom: "10px !important"}}>
        <Typography sx={{mr: '6px'}}>
        Pobrane z magazynu
        </Typography>
      </Grid>
      <Grid item xs={8} sx={{marginTop: "10px !important", marginBottom: "10px !important"}}>
        Historia transformacji z PZ
      </Grid>
    </Grid>
    {wz?.wydanieDetails.map(wydanieDetail => {
      return <Paper sx={{boxShadow: 3, p: '3px', mb: '20px'}} key={i++}>
        <Grid container rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}} sx={{textAlign: 'center'}}>
          <Grid item xs={2}>
            <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
              <Typography sx={{fontSize: 16, fontWeight: 'bold', pt: '10px', pb: '10px'}}>
              {wydanieDetail.towarName.slice(0, wydanieDetail.towarName.indexOf(' ', wydanieDetail.towarName.indexOf(' ') + 1))}<br></br>
              {wydanieDetail.towarName.slice(wydanieDetail.towarName.indexOf(' ', wydanieDetail.towarName.indexOf(' ') + 1))}
              </Typography>
              <Grid container rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}}>
                <Grid item xs={4}>
                  R. skrz
                </Grid>
                <Grid item xs={4}>
                  L. opak
                </Grid>
                <Grid item xs={4}>
                  Ilość
                </Grid>
                <Grid item xs={4}>
                {wydanieDetail.boxType}
                </Grid>
                <Grid item xs={4}>
                {wydanieDetail.numberOfBoxes}
                </Grid>
                <Grid item xs={4}>
                {wydanieDetail.count}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={10}>
            <Grid container item rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}}>
              
              {wydanieDetail.magazynToWydanieDetails.map(magazynToWydanieDetail => {
                return <Grid container item rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}} key={i++}>
                    <Grid item xs={2.3}>
                      <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
                        <Grid container rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}}>
                          <Grid container item rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}}>
                            <Grid item xs={10} sx={{mr: '-10px'}}>
                              <Grid container item rowSpacing={0} alignItems={'center'} justifyContent={'center'} columnSpacing={{xs: 0}}>
                                <Grid item xs={12} sx={{whiteSpace: 'nowrap'}}>
                                {magazynToWydanieDetail.dostawca}
                                </Grid>
                                <Grid item xs={12}>
                                {magazynToWydanieDetail.numberPZ}
                                </Grid>
                                <Grid item xs={12}>
                                {magazynToWydanieDetail.barcode}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={2}>
                            {magazynToWydanieDetail.doneZwrot ? <CallMissedOutgoingIcon style={{fontSize: '24px', color: 'black'}}/> : <></>}
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                          Ilość
                          </Grid>
                          <Grid item xs={4}>
                          Cena
                          </Grid>
                          <Grid item xs={4}>
                          Suma
                          </Grid>
                          <Grid item xs={4}>
                          {magazynToWydanieDetail.quantity}
                          </Grid>
                          <Grid item xs={4}>
                          {magazynToWydanieDetail.price}
                          </Grid>
                          <Grid item xs={4}>
                          {magazynToWydanieDetail.sumNetto}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={9.7}>
                      <Grid container item rowSpacing={0} alignItems={'center'} justifyContent={'left'} columnSpacing={{xs: 0}}>
                        {dostawaDetailDepth > 0 && <Grid item xs={calculateWidth}>
                          <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
                            {printText(magazynToWydanieDetail?.dostawaDetail)}
                          </Paper>
                        </Grid>}
                        {dostawaDetailDepth > 1 && <Grid item xs={calculateWidth}>
                          <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
                            {printText(magazynToWydanieDetail?.dostawaDetail?.dostawaDetail)}
                          </Paper>
                        </Grid>}
                        {dostawaDetailDepth > 2 && <Grid item xs={calculateWidth}>
                          <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
                            {printText(magazynToWydanieDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail)}
                          </Paper>
                        </Grid>}
                        {dostawaDetailDepth > 3 && <Grid item xs={calculateWidth}>
                          <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
                            {printText(magazynToWydanieDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail)}
                          </Paper>
                        </Grid>}
                        {dostawaDetailDepth > 4 && <Grid item xs={calculateWidth}>
                          <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
                            {printText(magazynToWydanieDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail)}
                          </Paper>
                        </Grid>}
                        {dostawaDetailDepth > 5 &&  <Grid item xs={calculateWidth}>
                          <Paper sx={{boxShadow: 3}} className='historyWzPaper'>
                            {printText(magazynToWydanieDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail?.dostawaDetail)}
                          </Paper>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
              })}
              
            </Grid>
          </Grid>
        </Grid>
      </Paper>
	 	})}
    </>}
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default ReportWzHistoryPage;